<template>
  <div class="cartItem">
    <div class="cartItem__thumbnail">
      <a :href="$fixUrlForCurrentLocale($productUrl(cartItem.product))">
        <SfImage
          class="cartItem-image"
          :src="cartItem.product.thumbnail.url"
          :alt="cartItem.product.name || 'alt'"
          :width="imageWidth"
          :height="imageHeight"
          image-tag="nuxt-img"
          :nuxt-img-config="{ format: 'webp' }"
          placeholder="/icons/components/product_placeholder.svg"
        />
      </a>
    </div>
    <div class="cartItem__data">
      <div class="cartItem__data__summary">
        <div class="information-container">
          <p class="cartItem__data__brand">
            {{ brandLabel }}
          </p>
          <p class="cartItem__data__name">{{ cartItem.product.name }}</p>
          <p
            v-for="(option, index) in cartItem.configurable_options"
            :key="`cart-item-option-${index}`"
            class="cartItem__data__option"
          >
            {{
              $t(
                option.option_label.charAt(0).toUpperCase() +
                  option.option_label.slice(1)
              )
            }}:

            {{
              `${
                option.value_label === 'TU'
                  ? $t('One Size')
                  : option.value_label
              }`
            }}
          </p>
        </div>
        <div class="price-container">
          <span class="cartItem__data__price">{{
            $n(cartItem.prices.price.value, 'currency')
          }}</span>
          <span v-if="cartItem.quantity > 1" class="cartItem__data__qty">{{
            'X ' + cartItem.quantity
          }}</span>
        </div>
      </div>
      <div class="cartItem__data__action">
        <div class="cartItem__data__action__update">
          <CustomSelect
            :key="'key' + updateKey"
            @selectChange="
              (e) => $emit('updateCartItemQty', { item: cartItem, quantity: e })
            "
            select-name="quantity"
            :selected-value="'' + cartItem.quantity"
            select-label=""
            :select-options="keys"
            :is-required="false"
            class="form__select sf-select--underlined"
          >
          </CustomSelect>
        </div>
        <div class="cartItem__data__action__remove">
          <a @click="$emit('removeItem', cartItem)">
            {{ $t('Remove ') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import CustomSelect from '~/components/General/CustomSelect.vue';

export default defineComponent({
  name: 'CardCartItem',
  components: { SfImage, CustomSelect },
  props: {
    cartItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    imageWidth: {
      type: String,
      default: '113',
    },
    imageHeight: {
      type: String,
      default: '140',
    },
    updateKey: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const brandLabel = computed(
      () => props.cartItem?.product?.brand_attribute?.label ?? ''
    );

    const keys = [
      ...new Array(props.cartItem.product.only_x_left_in_stock).keys(),
    ].map((number, index) => {
      if (index === 0) {
        return { label: number + 1, value: number + 1, default: true };
      }
      return { label: number + 1, value: number + 1 };
    });

    return {
      keys,
      brandLabel,
    };
  },
});
</script>

<style lang="scss" scoped>
.sf-sidebar__aside {
  .cartItem {
    @include for-desktop {
      height: 8.75rem;
    }
  }
  .cartItem__thumbnail {
    height: 8.75rem;
  }
  .cartItem__data {
    @include from-landscape-min {
      padding-left: 0.625rem;
      padding-top: 0.625rem;
      justify-content: unset;
    }
    p {
      width: 100%;
      margin: 0;
      padding-bottom: 0.188rem;
    }
    &__name {
      @include font-13x16;
      text-transform: lowercase;
      font-weight: 300;
    }
    &__brand {
      text-transform: uppercase;
      @include font-13x16;
      font-weight: 500;
    }
    &__price,
    &__qty {
      @include font-13x16;
      text-transform: uppercase;
    }
    &__qty {
      color: #777777;
      padding-left: 0.125rem;
    }
    &__option {
      @include font-12x16;
    }
    .price-container {
      display: flex;
      margin-top: auto;
      margin-bottom: 0.75rem;

      @include from-landscape-min {
      }
    }
    &__summary {
      display: flex;
      flex-direction: column;
      width: 65%;
    }
    &__action {
      display: flex;
      flex-direction: column;
      width: 35%;
      align-items: flex-end;
      margin-right: 0.625rem;

      &__update {
        display: flex;
        margin-bottom: auto;
      }
      &__remove {
        display: flex;
        margin-top: auto;
        margin-bottom: 0.75rem;
        a {
          color: black;
          @include font-12x16;
          cursor: pointer;
        }
      }
    }
  }
}
.cartItem {
  display: flex;
  width: 100%;
  height: 8.6875rem;
}
.cartItem__thumbnail {
  width: 7.063rem;
  background-color: #f1f1f1;
  & > a {
    height: 100%;
    display: flex;
    align-items: center;
    .cartItem-image {
      height: 100%;
    }
  }
}
@media (min-width: 1155px) {
  .cartItem {
    height: 13.125rem;
  }
  .cartItem__thumbnail {
    max-height: 13.125rem;
    max-width: 10.625rem;
    width: auto;
    height: auto;
    .cartItem-image {
      max-height: 13.125rem;
      img.sf-image {
        max-height: 13.125rem;
      }
    }
  }
}
.cartItem__data {
  width: 100%;
  padding-top: 0.625rem;
  padding-left: 0.625rem;
  display: flex;
  justify-content: space-between;
  @include from-landscape-min {
    justify-content: unset;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
  }
  p {
    width: 100%;
    margin: 0;
    @include for-mobile {
      padding-bottom: 0.188rem;
    }
  }
  &__name {
    @include font-13x16;
    text-transform: lowercase;
    font-weight: 300;
    @include for-desktop {
      padding-bottom: 1.25rem;
    }
  }
  &__brand {
    text-transform: uppercase;
    @include font-13x16;
    font-weight: 500;
  }
  &__price,
  &__qty {
    @include font-13x16;
    text-transform: uppercase;
  }
  &__qty {
    color: #777777;
    padding-left: 0.125rem;
  }
  &__option {
    @include font-12x16;
  }
  .price-container {
    display: flex;
    margin-top: auto;
    margin-bottom: 0.75rem;
  }
  &__summary {
    display: flex;
    flex-direction: column;
    width: 65%;
    .information-container {
      margin-right: 0.9rem;
    }
  }
  &__action {
    display: flex;
    flex-direction: column;
    width: 35%;
    align-items: flex-end;
    margin-right: 1.5625rem;
    &__update {
      display: flex;
      margin-bottom: auto;
    }
    &__remove {
      display: flex;
      margin-top: auto;
      margin-bottom: 0.75rem;
      a {
        color: black;
        @include font-12x16;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
#my-cart {
  .cartItem {
    &__data {
      &__action {
        &__update {
          .custom-select {
            &__container {
              height: 2.5rem;
              width: 4.375rem;
              #quantity {
                height: 2.5rem;
                padding: 0 0.9375rem;
                outline: 0.0625rem solid var(--c-grey);
                color: var(--c-black);
              }
              .custom-select {
                &__icon {
                  grid-column: 58 / span 95;
                }
              }
            }
          }
        }
      }
    }
  }
}
.sf-sidebar__aside {
  #my-cart {
    .cartItem {
      &__data {
        &__action {
          &__update {
            .custom-select {
              &__container {
                height: 2.5rem;
                width: 3.75rem;
              }
            }
          }
        }
      }
    }
  }
}
.cartItem__thumbnail {
  & > a {
    .cartItem-image {
      img.sf-image {
        object-fit: contain;
      }
    }
  }
}
</style>
