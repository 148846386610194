<template>
  <div id="cart" class="cart-mini">
    <!-- title must be not empty to display mini cart header -->
    <SfSidebar
      v-e2e="'sidebar-cart'"
      :visible="isCartSidebarOpen"
      class="sf-sidebar--right"
      position="right"
      @close="toggleCartSidebar"
    >
      <template #bar> <span /> </template>
      <template #circle-icon> <span /> </template>
      <template #title> <span /> </template>
      <template name="content-top">
        <div class="cart__sidebar__title">
          <span class="cart__sidebar__title__text">{{
            $t('Shopping Bag')
          }}</span>
          <CrossIcon
            class="cart__sidebar__title__icon"
            @click="toggleCartSidebar"
          />
        </div>
      </template>
      <transition name="sf-fade" mode="in-out">
        <div class="notifications remove-confirm">
          <SfNotification
            style="background: white; color: black"
            :visible="visible"
            title=""
            :message="
              $t(
                'Are you sure you want to remove this item from your shopping bag?'
              )
            "
            type="secondary"
          >
            <template #action>
              <div class="button-wrap">
                <CustomButton
                  :text="$t('Yes')"
                  @click="actionRemoveItem(tempProduct)"
                ></CustomButton>
                <CustomButton
                  :text="$t('Cancel')"
                  @click="visible = false"
                ></CustomButton>
              </div>
            </template>
            <template #close>
              <div />
            </template>
          </SfNotification>
        </div>
      </transition>
      <SfLoader v-if="isLoaderVisible" :loading="isLoaderVisible">
        <div />
      </SfLoader>
      <transition name="sf-fade" mode="in-out">
        <div
          v-if="totalItems && products"
          key="my-cart"
          class="my-cart"
          id="my-cart"
        >
          <div
            class="collected-product-list"
            v-if="!isLoaderVisible && !loading"
          >
            <div
              v-for="product in products"
              :key="getCartItemListKey(product)"
              class="cart-item-list"
            >
              <CardCartItem
                @removeItem="sendToRemove({ product })"
                @updateCartItemQty="updateCartItemQty"
                :cart-item="product"
              />
            </div>
          </div>
        </div>
        <div v-else key="empty-cart-container" class="empty-cart-container">
          <div class="empty-cart">
            <div class="empty-cart-shopping-block">
              <EmptyCart />
            </div>
          </div>
        </div>
      </transition>
      <template #content-bottom>
        <transition name="sf-fade">
          <div class="cart-sidebar-summary" v-if="totalItems">
            <div class="cart-sidebar-summary__subtotal">
              <p class="cart-sidebar-summary__subtotal__head">
                {{ $t('Subtotal') }}
              </p>
              <p class="cart-sidebar-summary__subtotal__head">
                {{ $n(cartGetters.getTotals(cart).total, 'currency') }}
              </p>
            </div>
            <div class="cart-sidebar-summary__actions">
              <CustomButton
                :text="$t('Proceed to checkout')"
                class="checkout-button"
                @click="goToCheckout"
              />
              <CustomButton
                :text="$t('Show cart')"
                class="show-cart-button"
                type="tertiary"
                @click="toggleCartSidebar"
                :link="$fixUrlForCurrentLocale('/cart')"
              />
            </div>
          </div>

          <div v-else>
            <CustomButton
              :text="$t('Torna allo shopping')"
              class="checkout-button"
              @click="toggleCartSidebar"
            />
          </div>
        </transition>
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import { SfLoader, SfNotification, SfSidebar } from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import {
  useCart,
  useUser,
  cartGetters,
  useExternalCheckout,
  useProduct,
} from '@gemini-vsf/composables';
import _debounce from 'lodash.debounce';
import { useUiState, useUiNotification, useGtm } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useAddToCart } from '~/helpers/cart/addToCart';
import CardCartItem from '~/components/Cart/CardCartItem.vue';
import { CrossIcon } from '~/components/General/Icons';
import CustomButton from '~/components/General/CustomButton.vue';
import EmptyCart from '~/components/Cart/EmptyCart.vue';
import useAtelier from '~/composables/useAtelier/useAtelier';
import removeOosItems from '~/helpers/cart/removeOosItems';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SfLoader,
    SfNotification,
    SfSidebar,
    CustomButton,
    CrossIcon,
    CardCartItem,
    EmptyCart,
  },

  setup() {
    const trans = useI18n();
    const { initializeCheckout } = useExternalCheckout();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const router = useRouter();
    const { app } = useContext();
    const {
      cart,
      removeItem,
      updateItemQty,
      loading,
      error: cartError,
    } = useCart();
    const { showCartError } = useAddToCart();
    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();
    const { search: productSearch, products: productSearchResult } =
      useProduct();

    const products = computed(() => cartGetters.getItems(cart.value));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const getAttributes = (product) => product.configurable_options || [];
    const visible = ref(false);
    const isLoaderVisible = ref(false);
    const tempProduct = ref();
    const productSKU = ({ product }) => product?.value?.merchant_sku;
    const { atelierStock, error: atelierError, getStock } = useAtelier();
    const { actionCartGtmPush } = useGtm();
    const gtmIndex = ref(1);
    const { removeUnavailableItems, success: removeOosSuccess } =
      removeOosItems();

    const actionRemoveItem = async (product) => {
      isLoaderVisible.value = true;

      await removeItem({
        product,
        customQuery: {
          removeItemFromCart: 'removeItemFromCartCustom',
        },
      });

      isLoaderVisible.value = false;
      visible.value = false;

      sendNotification({
        id: Symbol('product_removed'),
        message: `${cartGetters.getItemName(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          product
        )} ${trans.t('successfully removed from your cart')}`,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Product removed',
      });
      actionCartGtmPush(
        product.product,
        product.quantity,
        'remove_from_cart',
        gtmIndex.value,
        product.prices?.price?.value
      );
      gtmIndex.value += 1;
    };

    const sendToRemove = ({ product }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }

      visible.value = true;
      tempProduct.value = product;
    };

    const updateCartItemQty = async (data) => {
      const { quantity, item } = data;
      const productUid = item.product.uid.split('::')[1];
      const baseSearchQuery = {
        filter: {
          uid: productUid,
        },
      };
      await productSearch({
        queryType: 'DETAIL',
        customQuery: {
          productDetail: 'atelierId',
        },
        ...baseSearchQuery,
      });
      const atelierId = productSearchResult.value?.items?.[0]?.atelier_id;
      const sizeLabel = item.configurable_options?.find(
        (e) => e?.option_label === 'size' || e?.option_label === 'taglia'
      )?.value_label;
      await getStock(atelierId, sizeLabel);
      let stock =
        atelierStock.value && !atelierError.value
          ? Number.parseInt(atelierStock.value, 10) -
            Number.parseInt(quantity, 10) +
            1
          : 0;
      if (
        stock > 0 &&
        productSearchResult.value?.items?.[0]?.max_sal_qty !== null
      ) {
        let salableStock = productSearchResult.value.items[0].max_sal_qty;
        products.value?.forEach((cartItem) => {
          salableStock -=
            item.product.uid === cartItem.product.uid
              ? item.uid === cartItem.uid
                ? Number.parseInt(quantity, 10) - 1
                : Number.parseInt(cartItem.quantity, 10)
              : 0;
        });
        stock = salableStock < stock ? salableStock : stock;
      }
      if (stock < 1) {
        sendNotification({
          id: Symbol('Atelier OOS'),
          message: `${cartGetters.getItemName(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            item
          )} ${trans.t("doesn't have the quantity requested")}`,
          type: 'danger',
          icon: 'error',
          persist: false,
          title: 'Error',
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        await updateItemQty({
          product: item,
          quantity: Number.parseInt(quantity, 10),

          customQuery: {
            updateCartItems: 'updateCartItemsCustom',
          },
        });
        if (cartError.value.updateItemQty) {
          showCartError(cartError.value.updateItemQty);
        } else {
          sendNotification({
            id: Symbol('Atelier OOS'),
            message: `${cartGetters.getItemName(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              item
            )} ${trans.t('quantity in cart was changed')}`,
            type: 'success',
            icon: 'success',
            persist: false,
            title: 'Success',
          });
        }
      }
    };

    const getCartItemListKey = (cartItem) => {
      if (cartItem.configurable_options?.length > 0) {
        const variantKey = cartItem.configurable_options
          .map(
            (option) =>
              `${option.configurable_product_option_uid}-${option.configurable_product_option_value_uid}`
          )
          .join('-');
        return `${cartItem.product?.merchant_sku}-${variantKey}`;
      }

      return `${cartItem.product?.merchant_sku}`;
    };

    const goToCheckout = async () => {
      await removeUnavailableItems();
      if (removeOosSuccess.value) {
        sendNotification({
          id: Symbol('Atelier OOS'),
          message: `${trans.t('Click again to proceed to checkout.')}`,
          type: 'danger',
          icon: 'error',
          persist: false,
          title: 'Error',
        });
      } else {
        toggleCartSidebar();
        const redirectUrl = await initializeCheckout({
          baseUrl: '/checkout/user-account',
        });
        await router.push(`${app.$fixUrlForCurrentLocale(redirectUrl)}`);
      }
    };

    onMounted(async () => {
      await removeUnavailableItems();
    });

    return {
      trans,
      sendToRemove,
      actionRemoveItem,
      loading,
      isAuthenticated,
      products,
      removeItem,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateCartItemQty: _debounce(
        (...params) => updateCartItemQty(...params),
        1000
      ),
      isCartSidebarOpen,
      notifications,
      visible,
      isLoaderVisible,
      tempProduct,
      toggleCartSidebar,
      goToCheckout,
      totals,
      totalItems,
      cartGetters,
      getAttributes,
      sendNotification,
      productSKU,
      getCartItemListKey,
      cart,
    };
  },
});
</script>

<style lang="scss" scoped>
#cart {
  --sidebar-z-index: 3;
  --overlay-z-index: 3;
  .sf-overlay {
    display: none;
  }
}

.cart {
  &__sidebar {
    &__title {
      padding: 1.875rem 1.5625rem;
      display: flex;
      justify-content: space-between;
      &__text {
        @include font-16x16;
      }
      &__icon {
        @include pointer;
      }
    }
  }
}

.close-icon {
  position: fixed;
  right: 0.625rem;
  top: 0.625rem;
  cursor: pointer;
}
.notifications.remove-confirm {
  position: fixed;
  left: 50%;
  text-align: center;
  top: 50%;
  width: 21.875rem;
  margin-left: -10.9375rem;
  z-index: 99999;
  --notification-background: var(--c-black);
  --notifications-color: var(--c-white);
  --icon-color: var(--c-white);
  .color-secondary {
    cursor: pointer;
    @include font-16x16;
    @include for-mobile {
      max-width: unset;
      border-radius: unset;
      --notification-padding: 1rem;
      ::v-deep .sf-notification__title {
        padding: 0.5rem 0;
        --notification-title-font-weight: var(--font-weight-medium);
        --notification-title-font-size: var(--font-size--base);
      }
      ::v-deep .sf-notification__message {
        padding-bottom: 0.5rem;
      }
    }
    ::v-deep .button-wrap {
      padding-top: 0.625rem;
      display: flex;
      column-gap: 0.9375rem;
      width: 75%;
      margin: 0 auto;
    }
    ::v-deep .sf-notification {
      &__icon {
        display: none;
        margin: 0;
        @include for-mobile {
          svg.sf-icon-path {
            display: none;
          }
        }
      }
    }
  }
}
.cart-summary {
  margin-top: var(--spacer-xl);
}

.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__total-items {
    margin: 0;
  }

  &__total-price {
    --price-font-size: var(--font-size--xl);
    --price-font-weight: var(--font-weight--medium);
    margin: 0 0 var(--spacer-base) 0;
  }
}

.cart-mini {
  .title {
    @include font-16x16;
  }

  .collected-product-list {
    .cart-item-list {
      border-top: solid 1px #dadada;
      &:last-child {
        border-bottom: solid 1px #dadada;
      }
    }
  }

  .cart-item-list {
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<style lang="scss">
.sf-loader__spinner {
  filter: grayscale(100%);
}
.empty-cart-container {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  .empty-cart {
    .empty-cart-shopping-block {
      justify-content: center;
      .empty-cart-wrapper {
        .custom-button {
          display: none;
        }
        .sf-heading {
          text-align: center;
          padding-left: 1.5625rem;
          padding-right: 1.5625rem;

          &__title {
            padding-left: 2rem;
            padding-right: 2rem;
          }

          &__description {
            padding-top: 1.875rem;
          }
        }
      }
    }
  }
}

.cart-mini {
  position: relative;
  z-index: 100;
  .sf-header__logo {
    display: flex;
  }
  .sf-image--wrapper {
    width: 100%;
  }
  .sf-image--wrapper.empty-cart__image {
    margin: 0;
  }
  .cart-item-media {
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  p {
    margin: 0;
  }
  figure {
    display: flex;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  .sf-sidebar__bottom {
    padding: 1.25rem 1.875rem 1.875rem 1.875rem;
    .cart-sidebar-summary {
      &__subtotal {
        display: flex;
        justify-content: space-between;
        &__head {
          @include font-14x16;
        }
      }
      &__actions {
        text-align: center;
        .checkout-button {
          margin: 1.25rem 0;
        }
        .show-cart-button {
          margin: 1.25rem 0;
          color: var(--c-black);
        }
        .cart-button {
          @include font-14x16;
          text-decoration: underline;
        }
      }
    }
    .sf-button.color-secondary {
      background: white;
      color: black;
    }
    .sf-button.color-primary {
      border: 1px solid #707070;
      font-size: 12px;
      font-weight: 700;
      height: 65px;
      letter-spacing: 1px;
      line-height: 65px;
      padding: 0;
      &:hover {
        text-decoration-line: none;
      }
      &:active {
        text-decoration-line: none;
      }
      &:focus {
        text-decoration-line: none;
      }
    }
  }
  .cart-summary {
    border-bottom: 1px solid #c6c8c6;
    padding-bottom: 20px;
    margin: 0 0 24px;
  }
  .sf-sidebar__content {
    padding: 0px;
  }
  .sf-icon {
    margin: auto;
  }
  .sf-input {
    width: 45px;
    --input-height: 45px;
    padding: 0 5px;
    input {
      font-size: 16px;
      font-weight: 500;
      padding: 5px 0 0;
      &:focus-visible {
        outline: 0;
        border: 0;
      }
    }
  }

  .sf-sidebar__aside {
    hr {
      display: none;
    }
    height: 100%;
  }
}

#overlay_menu {
  top: 0;
}
</style>
