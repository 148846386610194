<template>
  <div class="empty-cart-wrapper">
    <SfHeading
      :title="$t('Your shopping cart is empty')"
      :level="2"
      :description="$t('You currently have no items in your cart.')"
      class="empty-cart-wrapper__title"
    />
    <CustomButton
      type="tertiary"
      :text="$t('Start the shopping')"
      link="/"
      class="empty-cart-wrapper"
    />
    <SfDivider
      class="custom-divider desktop-only"
      key="accordion-divider-cart"
    />
  </div>
</template>

<script>
import { SfHeading, SfDivider } from '@storefront-ui/vue';
import CustomButton from '~/components/General/CustomButton.vue';

export default {
  name: 'EmptyCart',
  components: {
    SfHeading,
    CustomButton,
    SfDivider,
  },
};
</script>

<style lang="scss" scoped>
.empty-cart-wrapper {
  hr {
    margin-top: 4.375rem;
  }
  .sf-heading {
    --heading-title-color: var(--c-text);
    --heading-title-margin: 0 0 0.3125rem 0;
    --heading-description-margin: 0 0 1rem 0;
    --heading-text-align: unset;
    text-align: var(--heading-text-align, unset);
    &__title {
      @include font-25x33;
      text-transform: uppercase;
      color: var(--c-text);
    }
    &__description {
      @include font-14x19;
    }
  }
}
</style>
